p, a, ul, ol, h1,h2,h3,h4 { font-family: 'Montserrat';}

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');



:root {
  --color-main: #f68657;
  --color-text: #383a3f;
  --color-dark: #1f2124;
  --color-gray: #677;
  --color-bg: #f3f6f9;
  --color-light: #acb4bd;
  --color-lighter: #f9f9f9;
  --color-white: #fff;
  --color-border:#e0e3e6;
  --inputs-bg: #2a2a2a;
}

.dark {
  --color-main: #f68657;
  --color-text: #d6d1d1;
  --color-dark: #f5f6f7;
  --color-gray: #999;
  --color-bg: #1f2124;
  --color-lighter: #292a2c;
  --color-white: #2e3235;
  --color-border:#252629;
}

.coffee {
  --color-accent: #fcbf26;
  --color-accent-light: #fdce59;
  --color-green: #4d5928;
  --color-text: #d6d1d1;
  --color-dark: #f5f6f7;
  --color-gray: #999;
  --color-bg: #2c1b11;
  --color-lighter: #4a3427;
  --color-border:#2c1b11;
}

.greeny {
  --color-accent: #96b700;
    --color-accent-light: #85b03d;
    --color-green: #85ab3c;
    --color-green-dark: #185533;
    --color-text: #85ab3c;
    --color-dark: #0d2517;
    --color-gray: #85ab3c;
    --color-bg: #f2efcc;
    --color-border: #f8f7c7;
    --color-white: #f2efcc;
    --inputs-bg: #f2efcc;
    --inputs-text: #0d2517;
}

/* BASE STYLES */


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
  color: inherit;
  font-size: inherit;
  scroll-behavior: smooth;
}


body{
  line-height: 1.8em;
  font-weight: 400;
  font-size: 16px;
}

a {
  text-decoration: none;
}

/* APP STYLES */


.container {
  width: 100%;
  min-height: 100vh;
  color: var(--color-text);
  background-color: var(--color-bg);
  display: flex;
  align-items: center;
}
@media screen and (max-width: 767px) {
  .container {
    padding: 30px;
  }
}


.app {
  width: 100%;
  max-width: 480px;
  min-height: 88vh;
  margin: 0 auto;
  background-color: var(--color-green-dark);
  box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.05);
  position: relative;
  border-radius: 30px;
}


.app-header {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 28px 30px;
  justify-content: space-between;
  background-color: var(--color-green);
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 30px 30px 0 0;
}

.app-header h1 {
  font-family: 'Titan One';
  font-size: 30px;
  color: var(--color-dark);
  font-weight: 400;
  text-align: center;
}

.app-header button {
  border: 0;
  background: transparent;
  cursor: pointer;
}

.app-header button > svg {
  fill: var(--color-dark);
  height: 25px;
  width: 25px;
  object-fit: cover;
}

.app-body {
  padding: 16px;
}

.back-button {
  background: var(--color-accent);
  padding: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -60px;
  position: absolute;
  top: 20px;
}

.back-button svg {
  margin-right: 0px;
  color: var(--color-bg);
}

.back-button:hover {
  background: var(--color-bg);
}
.back-button:hover svg {
  color: var(--color-accent);
}


/* RECIPES STYLES */
.recipes {
  width: 100%;
}

.recipes-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 16px;
}

.recipes-title,
.recipes-count {
  color: var(--color-accent-light);
  font-size: 24px;
  font-weight: 600;
}

.recipes-count {
  font-size: 18px;
  color: var(--color-gray);
}


.recipes-list {
  padding: 0;
  margin: 16px 0;
  height: 70vh;
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
}

.recipes-list::-webkit-scrollbar {
  display: none;
}

.recipes-list-item {
  border-bottom: 3px solid  var(--color-border);
  margin-bottom: 0px;
  padding: 8px 16px;
  transition: all 0.2s ease-in-out;
}

.recipes-list-item:hover {
  background-color: var(--color-bg);
  cursor: pointer;
}

.recipes-list-item:hover h3, .recipes-list-item:hover p, .recipes-list-item:hover p span {
  color: var(--color-dark);
}

.recipes-list-item h3 {
  font-weight: 800;
}

.recipes-list-item p span {
  font-weight: 600;
}


.recipes-list-item p span {
  color: var(--color-bg);
  display: inline-block;
  margin-right: 8px;
}

.recipes-list-item p {
  font-size: 14px;
  color: var(--color-bg);
}

.floating-button {
  font-size: 48px;
  position: absolute;
  bottom: -24px;
  right: -16px;
  background: var(--color-accent-light);
  border: none;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2);
}


.floating-button > svg {
  fill: var(--color-bg);
   width: 30px;
    height: 30px;
}

.wrapper {
  flex-direction: column;
  display: flex;
  justify-content: center;
}

/*==============================
=>  Recipe Styles
================================*/
.recipe {
  padding: 0 30px;
}

.recipe h3 {
  font-size: 24px;
}

.recipe-header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: var(--color-accent-light);
  padding: 10px;
}

.recipe-header-content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.recipe-header h3 {
  display: flex;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.recipe-header h3 svg {
  width: 20px;
}

.recipe-header button {
  border: none;
  outline: none;
  font-weight: 600;
  background-color: transparent;
  font-size: 18px;
  cursor: pointer;
  border: 2px solid;
  padding: 10px 16px;
  border-radius: 30px;
}

.recipe-header button:hover {
  background-color: #bf6652;
  color: var(--color-bg);
}

.recipe .recipe-title {
  font-size: 46px;
  line-height: 1em;
  font-weight: 900;
  margin-bottom: 10px;
  color: var(--color-accent-light);
}

.recipe .recipe-description {
  margin-bottom: 20px;
  font-size: 20px;
  color: var(--color-bg);
}

.recipe .recipe-meta {
  font-size: 18px;
  font-weight: 400;
}

.recipe .recipe-section-title {
  color: var(--color-bg);
  font-weight: 800;
  font-size: 33px;
  margin-bottom: 15px;
}

.recipe .recipe-ingredients-list {
  list-style: none;
  margin-bottom: 20px;
}

.recipe-ingredients-list li {
  background: var(--color-bg);
  color: var(--color-green-dark);
  padding: 5px 15px;
  font-weight: 600;
  border-left: solid 25px var(--color-accent-light);
  font-size: 20px;
  margin-bottom: 10px;
  border-radius: 30px;
}

.recipe .recipe-directions-list {
  margin-bottom: 20px;
  padding-left: 25px;
}

.recipe-directions-list li {
  background: var(--color-bg);
  color: var(--color-green-dark);
  padding: 5px 15px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 10px;
  border-radius: 30px;
}

.recipe-directions-list li::marker {
  background: var(--color-bg);
  padding: 10px !important;
  color: var(--color-accent-light);
  font-weight: 900;
  font-size: 25px;
}

/* FORM STYLES */
.recipe form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  padding-bottom: 40px;
}

.recipe textarea:active,
.recipe textarea:focus,
.recipe input:active,
.recipe input:focus {
  outline: none;
  border: none;
}

.recipe label {
  font-size: 20px;
  font-weight: 700;
}

.recipe hr {
  border: none;
  outline: none;
  background: var(--color-bg);
  height: 2px;
  margin: 15px 0;
}

.recipe textarea {
  background-color: var(--inputs-bg);
  color: var(--inputs-text);
  border: none;
  border-radius: 20px;
  padding: 16px 12px;
  width: 100%;
  height: 6em;
  resize: none;
  scrollbar-width: none; /* Firefox */
  margin: 10px 0;
}
.recipe textarea::-webkit-scrollbar {
  display: none;
}

.ingredient-inputs, .direction-inputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  background: var(--color-green);
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
}
.ingredient-inputs input[type="text"], .direction-inputs input[type="text"] {
  padding: 5px 15px;
  font-size: 16px;
  margin: 5px 0;
}
.ingredient-inputs label, .direction-inputs label {
  font-size: 18px;
  color: var(--color-dark);
}

input:not([type="button"]) {
  font-size: 20px;
  font-weight: 500;
  background: var(--inputs-bg);
  border: none;
  padding: 7px 20px;
  border-radius: 20px;
  margin: 10px 0;
  color: var(--color-green-dark);
}

input[type="button"] {
  color: var(--color-accent-light);
  background: transparent;
  border: 2px solid var(--color-accent-light);
  border-radius: 20px;
  padding: 10px 22px;
  margin: 10px 0;
  cursor: pointer;
  font-weight: 800;
  transition: all 0.4s ease;
}
input[type="button"]:hover {
  background: var(--color-accent-light);
  color: white;
}

button[type="submit"] {
  color: var(--color-accent-light);
  background: white;
  border: 2px solid white;
  border-radius: 20px;
  padding: 10px 22px;
  margin: 10px 0;
  cursor: pointer;
  font-weight: 800;
  transition: all 0.4s ease;
}

button[type="submit"]:hover {
  color: white;
  background: var(--color-accent-light);
  border-color: var(--color-accent-light);
}